import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import People from "@mui/icons-material/People";
import Avatar from "@mui/material/Avatar";
import logo from "./../../images/logo3.png";
import Stack from "@mui/material/Stack";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SendIcon from "@mui/icons-material/Send";

import HouseIcon from "@mui/icons-material/House";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import NoInternet from "./../../pages/NoInternet";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { dispatch: authDispatch } = useContext(AuthContext);
  const navitage = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    authDispatch({ type: "LOGOUT" });
    navitage("/");
  };
  const isOnline = () => {
    return window.navigator.onLine;
  };

  return isOnline() ? (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <Stack direction="row" spacing={1}>
            <Avatar
              className="logo"
              alt="ParaMart Store"
              src={logo}
              sx={{ width: 50, height: 50 }}
            />
            <span className="logo">ParaMart Store</span>
          </Stack>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>

          <p className="title">LISTS</p>

          {/* <Link to="/categories" style={{ textDecoration: "none" }}>
            <li>
              <CategoryIcon className="icon" />
              <span>Categories</span>
            </li>
          </Link> */}

          {/* <Link to="/banners" style={{ textDecoration: "none" }}>
            <li>
              <PhotoLibraryIcon className="icon" />
              <span>Banners</span>
            </li>
          </Link> */}

          {/* <Link to="/stores" style={{ textDecoration: "none" }}>
            <li>
              <StoreMallDirectoryIcon className="icon" />
              <span>Stores</span>
            </li>
          </Link> */}

          {/* <Link to="/inhouse-products" style={{ textDecoration: "none" }}>
            <li>
              <HouseIcon className="icon" />
              <span>In-House Products</span>
            </li>
          </Link> */}

          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <ShoppingBagIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>

          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>

          {/* <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <People className="icon" />
              <span>Users</span>
            </li>
          </Link> */}
          {/* <li>
            <LocalShippingIcon className="icon" />
            <span>Delivery</span>
          </li> */}

          {/* <p className="title">USEFUL</p>
          <Link to="/notifications" style={{ textDecoration: "none" }}>
            <li>
              <SendIcon className="icon" />
              <span>Notifications</span>
            </li>
          </Link>

          <Link to="/generateCode" style={{ textDecoration: "none" }}>
            <li>
              <HowToRegIcon className="icon" />
              <span>Generate Sign-Up Code</span>
            </li>
          </Link> */}
          {/* <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li>  */}
          <p className="title">USER</p>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>
          <li>
            <div onClick={handleLogout}>
              <ExitToAppIcon className="icon" />
              <span>Logout</span>
            </div>
          </li>
          {/* <li>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
            <div onClick={() => dispatch({ type: "DARK" })}>
              <span>Logout</span>
            </div>
          </li> */}
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  ) : (
    <NoInternet />
  );
};

export default Sidebar;
