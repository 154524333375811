import "./datatable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  userColumns,
  productColumns,
  ordersColumns,
  categoriesColumns,
  bannersColumns,
  generatedCodeColumns,
} from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useContext } from "react";
import { AuthContext } from "./../../context/AuthContext";

// const Datatable = () => {
const Datatable = ({ fireTable, title }) => {
  const [data, setData] = useState([]);
  const [inHouseProducts, setInHouseProducts] = useState(false);
  const { currentUser } = useContext(AuthContext);

  let columnTable;
  let filterTable;
  let newPath = "/" + fireTable + "/new";
  if (fireTable === "products") {
    columnTable = productColumns;
    filterTable = "createdAt";
    // newPath = "/inhouse-products/new";
  } else if (fireTable === "orders") {
    columnTable = ordersColumns;
    filterTable = "orderDate";
  } else if (fireTable === "categories") {
    columnTable = categoriesColumns;
    filterTable = "createdAt";
  }
  // else if (fireTable === "products" && inHouse === "inHouse") {
  //   columnTable = productColumns;
  //   filterTable = "createdAt";
  //   newPath = "/inhouse-products/new";
  //   setInHouseProducts(true);
  // }

  useEffect(() => {
    let fetchData;
    // LISTEN (REALTIME)
    if (fireTable === "products") {
      fetchData = async () => {
        let list = [];
        const productData = query(
          collection(db, fireTable),
          where("storeUid", "==", currentUser),
          orderBy("createdAt", "desc")
        );
        try {
          const querySnapshot = await getDocs(productData);
          querySnapshot.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setData(list); // console.log(odersList);
        } catch (err) {
          console.log(err);
        }
      };
    } else if (fireTable === "orders") {
      fetchData = async () => {
        let list = [];
        const productData = query(
          collection(db, fireTable),
          where("storeUid", "==", currentUser),
          orderBy("orderDate", "desc")
        );
        try {
          const querySnapshot = await getDocs(productData);
          querySnapshot.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setData(list); // console.log(odersList);
        } catch (err) {
          console.log(err);
        }
      };
    } else {
      fetchData = onSnapshot(
        collection(db, fireTable),
        (snapShot) => {
          let list = [];
          snapShot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setData(list);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (fireTable === "orders" || fireTable === "products") {
      fetchData();
    } else {
      return () => {
        fetchData();
      };
    }
  }, [fireTable, filterTable, currentUser]);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, fireTable, id));
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={params.row.id.toString()}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            {fireTable !== "orders" ? (
              <div
                className="deleteButton"
                onClick={() => {
                  confirmAlert({
                    title: "Confirm to submit",
                    message:
                      params.row.title !== undefined
                        ? "Are you sure you want to delete " +
                          params.row.title.toUpperCase()
                        : params.row.name !== undefined
                        ? "Are you sure you want to delete " +
                          params.row.name.toUpperCase()
                        : "Are you sure you want to delete " + params.row.code,
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => handleDelete(params.row.id),
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                }}
              >
                Delete
              </div>
            ) : (
              <div></div>
            )}
          </div>
        );
      },
    },
  ];
  if (fireTable === "orders" || fireTable === "categories") {
    return (
      <div className="datatable">
        <div className="datatableTitle">
          {title}
          {/* <Link to={newPath} className="link">
          Add New
        </Link> */}
        </div>

        <DataGrid
          className="datagrid"
          rows={data}
          columns={
            fireTable === "categories"
              ? columnTable
              : columnTable.concat(actionColumn)
          }
          pageSize={9}
          rowsPerPageOptions={[9]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "orderDate",
                  sort: "desc",
                },
              ],
            },
          }}
          // checkboxSelection
        />
      </div>
    );
  } else {
    return (
      <div className="datatable">
        <div className="datatableTitle">
          Add New {title}
          <Link to={newPath} className="link">
            Add New
          </Link>
        </div>

        <DataGrid
          className="datagrid"
          rows={data}
          columns={columnTable.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}

          // checkboxSelection
        />
      </div>
    );
  }
};

export default Datatable;
