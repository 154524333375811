import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "../../firebase";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Featured = () => {
  const { currentUser } = useContext(AuthContext);

  const [total, setTotal] = useState(0);
  const [totalLBP, setTotalLBP] = useState(0);

  const [sales, setSales] = useState(0);

  const [totalWeek, setTotalWeek] = useState(0);
  const [totalLBPWeek, setTotalLBPWeek] = useState(0);

  const [totalMonth, setTotalMonth] = useState(0);
  const [totalLBPMonth, setTotalLBPMonth] = useState(0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const lastWeek = new Date(new Date().setDate(today.getDate() - 7));
  const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));

  useEffect(() => {
    const fetchTotal = async () => {
      const totalData = query(
        collection(db, "orders"),
        where("storeUid", "==", currentUser),
        where("orderDate", ">=", today),
        where("orderDate", "<", tomorrow)
      );

      const querySnapshot = await getDocs(totalData);
      setSales(querySnapshot.docs.length);
      let newTotal = 0;
      let newTotalLBP = 0;
      querySnapshot.forEach((doc) => {
        if (doc.data().orderStatus !== "Cancelled") {
          newTotal += doc.data().totalPrice;
          newTotalLBP += doc.data().totalLBP;
        }
        // setOrderStatus(doc.data().orderStatus);
      });
      setTotal(newTotal.toLocaleString());
      setTotalLBP(newTotalLBP.toLocaleString());
    };

    const fetchTotalWeek = async () => {
      const totalData = query(
        collection(db, "orders"),
        where("storeUid", "==", currentUser),
        where("orderDate", "<=", today),
        where("orderDate", ">", lastWeek)
      );

      const querySnapshot = await getDocs(totalData);
      let newTotal = 0;
      let newTotalLBP = 0;
      querySnapshot.forEach((doc) => {
        if (doc.data().orderStatus !== "Cancelled") {
          newTotal += doc.data().totalPrice;
          newTotalLBP += doc.data().totalLBP;
        }
        // setOrderStatus(doc.data().orderStatus);
      });
      setTotalWeek(newTotal.toLocaleString());
      setTotalLBPWeek(newTotalLBP.toLocaleString());
    };

    const fetchTotalMonth = async () => {
      const totalData = query(
        collection(db, "orders"),
        where("storeUid", "==", currentUser),
        where("orderDate", "<=", today),
        where("orderDate", ">", lastMonth)
      );

      const querySnapshot = await getDocs(totalData);
      let newTotal = 0;
      let newTotalLBP = 0;
      querySnapshot.forEach((doc) => {
        if (doc.data().orderStatus !== "Cancelled") {
          newTotal += doc.data().totalPrice;
          newTotalLBP += doc.data().totalLBP;
        }
        // setOrderStatus(doc.data().orderStatus);
      });
      setTotalMonth(newTotal.toLocaleString());
      setTotalLBPMonth(newTotalLBP.toLocaleString());
    };

    fetchTotal();
    fetchTotalWeek();
    fetchTotalMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(total);
  let saleText = sales + "%";

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Revenue</h1>
        {/* <MoreVertIcon fontSize="small" /> */}
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={sales} text={saleText} strokeWidth={5} />
        </div>
        <p className="title">Total sales made today</p>
        <p className="amount">{total} $</p>
        <p className="amount">{totalLBP} LBP</p>
        <p className="desc">
          Previous transactions processing. Last payments may not be included.
        </p>
        <div className="summary">
          {/* <div className="item">
            <div className="itemTitle">Target</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small" />
              <div className="resultAmount">$12.4k</div>
            </div>
          </div> */}
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              {/* <KeyboardArrowUpOutlinedIcon fontSize="small" /> */}
              <div className="resultAmount">{totalWeek} $</div>
            </div>
            <div className="itemResult positive">
              <div className="resultAmount">{totalLBPWeek} LBP</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              {/* <KeyboardArrowUpOutlinedIcon fontSize="small" /> */}
              <div className="resultAmount">{totalMonth} $</div>
            </div>
            <div className="itemResult positive">
              <div className="resultAmount">{totalLBPMonth} LBP</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
