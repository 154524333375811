import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import axios from "axios";
// import { getAnalytics } from "firebase/analytics";
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: "ecommerce-76e76.firebaseapp.com",
//   projectId: "ecommerce-76e76",
//   storageBucket: "ecommerce-76e76.appspot.com",
//   messagingSenderId: "1045778202290",
//   appId: "1:1045778202290:web:47e26e7366ee608f3053ab",
// };
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "paramart-16ceb.firebaseapp.com",
  projectId: "paramart-16ceb",
  storageBucket: "paramart-16ceb.appspot.com",
  messagingSenderId: "826890558124",
  appId: "1:826890558124:web:0ebbe80014ee231fd5dd09",
  measurementId: "G-4DXS7ZP2NL",
};
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);

// Send Notification

export const sendNotification = async (token, title, body) => {
  // console.log("token");
  try {
    const response = await axios.post(
      "https://fcm.googleapis.com/fcm/send",
      {
        priority: "high",
        data: {
          click_action: "FLUTTER_NOTIFICATION_CLICK",
          status: "done",
          body,
          title,
        },
        notification: {
          title,
          body,
        },
        to: token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `key=AAAAwIaEmqw:APA91bGEg6tMqlVA0o2rXYqf-lqPQalFarQqNZq6tkKRNDhe7MfWLGp3gbZmKhVkywgUYyKeLx7jyHKZ0J3Ug-UheANO7d90gULPhoGahO1gms21T5t8lXQ5hf5_Y13QheSpVrbbvj1q`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};

// Send the notification
// export const sendNotification = (token, data) => {
//   messaging
//     .sendToDevice(token, { data })
//     .then((response) => {
//       console.log("Successfully sent message:", response);
//     })
//     .catch((error) => {
//       console.log("Error sending message:", error);
//     });
// };
// messaging
//   .sendToDevice(userDeviceToken, {
//     data: {
//       title: "Notification Title",
//       body: "Notification Body",
//     },
//   })
//   .then((response) => {
//     console.log("Successfully sent message:", response);
//   })
//   .catch((error) => {
//     console.log("Error sending message:", error);
//   });
