import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import SignUp from "./pages/login/SignUp";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import SingleView from "./pages/single_view/SingleView";
import New from "./pages/new/New";
import InvoiceTemp from "./pages/invoice/InvoiceTemp";
import Notification from "./pages/notification/Notification";
import NewProduct from "./pages/new_product/NewProduct";
import EditProduct from "./pages/new_product/EditProduct";
import NewCatBan from "./pages/new_category_banner/NewCatBan";
import EditCatBan from "./pages/new_category_banner/EditCatBan";
import NewCode from "./pages/new_generated_code/NewCode";
import EditCode from "./pages/new_generated_code/EditCode";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  productInputs,
  newProductInputs,
  userInputs,
  categoryInputs,
  bannerInputs,
  userInputsUpdates,
  generatedCodeInputs,
} from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import NotFound from "./pages/NotFound";
import NoInternet from "./pages/NoInternet";
import ProfilePage from "./pages/profile/profile";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
  const isOnline = () => {
    return window.navigator.onLine;
  };

  return isOnline() ? (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="signup">
              <Route
                index
                element={
                  <RequireAuth>
                    <SignUp />
                  </RequireAuth>
                }
              />
            </Route>
            {/* <Route path="categories">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="categories" title="Categories" />
                  </RequireAuth>
                }
              /> */}
            {/* <Route
                path=":categoryId"
                element={
                  <RequireAuth>
                    <EditCatBan
                      inputs={categoryInputs}
                      title="Edit Category"
                      fireTable="categories"
                    />
                  </RequireAuth>
                }
              />

              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewCatBan
                      inputs={categoryInputs}
                      title="Add New Category"
                      fireTable="categories"
                    />
                  </RequireAuth>
                }
              /> */}
            {/* </Route> */}

            {/* <Route path="banners">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="banners" title="Banners" />
                  </RequireAuth>
                }
              />
              <Route
                path=":bannerId"
                element={
                  <RequireAuth>
                    <EditCatBan
                      inputs={bannerInputs}
                      title="Edit Banner"
                      fireTable="banners"
                    />
                  </RequireAuth>
                }
              />

              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewCatBan
                      inputs={bannerInputs}
                      title="Add New Banner"
                      fireTable="banners"
                    />
                  </RequireAuth>
                }
              />
            </Route> */}
            {/* <Route path="stores">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="vendors" title="Stores" />
                  </RequireAuth>
                }
              />
              <Route
                path=":storeId"
                element={
                  <RequireAuth>
                    <Single fireTable="vendors" />
                  </RequireAuth>
                }
              />

              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New inputs={userInputs} title="Add New Store" />
                  </RequireAuth>
                }
              />
            </Route> 

            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="users" title="Users" />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <Single fireTable="users" />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId/:orderId"
                element={
                  <RequireAuth>
                    <SingleView />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New inputs={userInputs} title="Add New User" />
                  </RequireAuth>
                }
              />
            </Route>
            */}

            <Route path="products">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="products" title="Products" />
                  </RequireAuth>
                }
              />
              <Route
                path=":productId"
                element={
                  <RequireAuth>
                    {/* <Single /> */}
                    <EditProduct inputs={productInputs} title="Edit Product" />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewProduct
                      inputs={newProductInputs}
                      title="Add New Product"
                    />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="inhouse-products">
              <Route
                index
                element={
                  <RequireAuth>
                    <List
                      fireTable="products"
                      title="Products"
                      inHouse="inHouse"
                    />
                  </RequireAuth>
                }
              />
              <Route
                path=":productId"
                element={
                  <RequireAuth>
                    {/* <Single /> */}
                    <EditProduct inputs={productInputs} title="Edit Product" />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewProduct
                      inputs={newProductInputs}
                      title="Add New Product"
                    />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="orders">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="orders" title="Orders" />
                  </RequireAuth>
                }
              />
              <Route
                path=":orderId"
                element={
                  <RequireAuth>
                    <SingleView />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="profile">
              <Route
                index
                element={
                  <RequireAuth>
                    <ProfilePage inputs={userInputsUpdates} title="Your Info" />
                  </RequireAuth>
                }
              />
              <Route
                path=":orderId"
                element={
                  <RequireAuth>
                    <SingleView />
                  </RequireAuth>
                }
              />
            </Route>
            {/* 
            <Route path="notifications">
              <Route
                index
                element={
                  <RequireAuth>
                    <Notification />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="generateCode">
              <Route
                index
                element={
                  <RequireAuth>
                    <List fireTable="generateCode" title="Sign Up Code" />
                  </RequireAuth>
                }
              />
              <Route
                path=":generateCodeID"
                element={
                  <RequireAuth>
                    <EditCode
                      inputs={generatedCodeInputs}
                      title="Edit Code"
                      fireTable="generateCode"
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewCode
                      inputs={generatedCodeInputs}
                      title="Add New Code"
                      fireTable="generateCode"
                    />
                  </RequireAuth>
                }
              />
            </Route> */}

            <Route path="invoice/:orderId">
              <Route
                index
                element={
                  // <RequireAuth>
                  <InvoiceTemp />
                  // </RequireAuth>
                }
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  ) : (
    <NoInternet />
  );
}

export default App;
