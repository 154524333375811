import "./profile.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { doc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/joy/CircularProgress";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import { AuthContext } from "./../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 80,
    paddingTop: "56.25%", // 16:9
  },
}));

const Profile = ({ inputs, title }) => {
  const { currentUser } = useContext(AuthContext);
  const classes = useStyles();

  const userId = currentUser ? currentUser : "";
  // const userId = currentUser ? currentUser.uid : "";
  // console.log(currentUser);

  const [userData, setuserData] = useState([]);

  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [dateCreated, setdateCreated] = useState("");
  const [imageURLCat, setImageURLCat] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const userDataFire = query(
        collection(db, "vendors"),
        where("id", "==", userId)
      );

      const querySnapshot = await getDocs(userDataFire);
      querySnapshot.forEach((doc) => {
        setuserData({ id: doc.id, ...doc.data() });
        const timestampDate = doc.data().createdAt.toDate();
        const formattedDate = timestampDate.toLocaleString();
        setdateCreated(formattedDate);
        // setdateCreated(doc.data().createdAt);
      });
    };
    fetchUser();
  }, [userId]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, "storeImages/" + name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, imageUrl: downloadURL }));
            setImageURLCat(downloadURL);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  // console.log(data);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value.toLowerCase();

    setData({ ...data, [id]: value });
  };
  // const productUid = v4();

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateDoc(doc(db, "vendors", userData.id), {
        ...data,
      });
      await updateDoc(doc(db, "categories", userData.id), {
        imageUrl: imageURLCat,
      });
      // navigate(-1);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>{title}</h1>
        </div>

        <div className="bottom">
          <div className="left">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={10}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image={
                      file
                        ? URL.createObjectURL(file)
                        : userData.imageUrl
                        ? userData.imageUrl
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    title={userData.companyName}
                  />
                  {/* ["h1","h2","h3","h4","h5","h6","subtitle1","subtitle2","body1","body2","caption","button","overline","srOnly","inherit"] */}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {userData.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      component="p"
                    >
                      Company: {userData.companyName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Phone Number: {userData.phoneNumber}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Email: {userData.email}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Address: {userData.shippig_address}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Joined: {dateCreated}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* <img
              src={file ? URL.createObjectURL(file) : productData.imageUrl}
              alt=""
            /> */}
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => {
                return (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      required
                      readOnly={input.readOnly ? true : false}
                      defaultValue={userData[input.id]}
                    />
                  </div>
                );
              })}

              <div className="sendButton">
                {loading ? (
                  <CircularProgress color="success" />
                ) : (
                  <button disabled={per !== null && per < 100} type="submit">
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
