import "./newProduct.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import CategoryCombobox from "./CategoryCombobox";
import * as React from "react";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AuthContext } from "./../../context/AuthContext";
import { collection, query, where, getDocs } from "firebase/firestore";
import ProductImages from "./ProductImages";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";

import { v4 } from "uuid";

const NewProduct = ({ inputs, title }) => {
  const { currentUser } = useContext(AuthContext);

  // console.log("User: ", currentUser);
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [storeUid, setStoreUid] = useState("");
  const navigate = useNavigate();
  const [onSaleChecker, setOnSaleChecker] = useState(false);
  const [dollarChecker, setDollarChecker] = useState(false);
  const [description, setDescription] = useState("");
  const [firstImage, setFirstImage] = useState([]);
  // const [productSize, setProductSize] = useState("");
  const [productAdvertise, setProductAdvertise] = useState("");

  const [newSize, setNewSize] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newSale, setNewSale] = useState("");
  const [sizes, setSizes] = useState([]);
  // const [sizes, setSizes] = useState([
  //   { size: "Small", price: 10.0 },
  //   { size: "Medium", price: 15.0 },
  //   { size: "Large", price: 20.0 },
  // ]);

  const handleSaleChange = (event) => {
    setOnSaleChecker(event.target.checked);
  };
  const handleDollarChange = (event) => {
    setDollarChecker(event.target.checked);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userDataFire = query(
        collection(db, "vendors"),
        where("id", "==", currentUser)
      );
      setStoreUid(currentUser);
      const querySnapshot = await getDocs(userDataFire);
      querySnapshot.forEach((doc) => {
        // setuserData({ id: doc.id, ...doc.data() });

        setStoreId(doc.data().companyName);
      });
    };

    const fetchCategoryData = async () => {
      const categoryDataFire = query(
        collection(db, "categories"),
        where("id", "==", currentUser)
      );

      const querySnapshot = await getDocs(categoryDataFire);
      querySnapshot.forEach((doc) => {
        setCategoryId(doc.data().title);
      });
    };

    fetchUserData();
    fetchCategoryData();
  }, [currentUser]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, "productsImages/" + name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, imageUrl: downloadURL }));
            setFirstImage(downloadURL);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  // console.log(data);
  const productUid = v4();

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value.toLowerCase();

    setData({ ...data, [id]: value });
    console.log("Data: ", data);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, "products", productUid), {
        ...data,
        id: productUid,
        productCategoryName: categoryId,
        isOnSale: onSaleChecker,
        isDollar: dollarChecker,
        isPiece: false,
        description: description,
        storeId: storeId,
        storeUid: storeUid,
        productImages: [firstImage],
        productSize: "",
        productAdvertise: productAdvertise,
        productSizePrice: sizes,
        createdAt: serverTimestamp(),
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };
  const setCategoryIdFun = (id) => {
    setCategoryId(id);
    // console.log ("SelectCat onSet: ", id)
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newSizes = [
      ...sizes,
      { size: newSize, price: newPrice, sale: newSale },
    ];
    setSizes(newSizes);

    setNewSize("");
    setNewPrice("");
    setNewSale("");
  };
  function handleDeleteSizes(index) {
    const updatedSizes = [...sizes];
    updatedSizes.splice(index, 1);

    setSizes(updatedSizes);
  }
  // {selectedItem && {selectedItem.id} }
  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {/* <Navbar /> */}
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
              <Box>
                <form onSubmit={handleSubmit}>
                  <label>
                    Size:
                    <input
                      type="text"
                      value={newSize}
                      placeholder="100ML"
                      required
                      onChange={(event) => setNewSize(event.target.value)}
                    />
                  </label>
                  <label>
                    Price:
                    <input
                      type="text"
                      value={newPrice}
                      placeholder="100"
                      required
                      onChange={(event) => setNewPrice(event.target.value)}
                    />
                  </label>
                  <label>
                    Sale:
                    <input
                      type="text"
                      value={newSale}
                      placeholder="80"
                      required
                      onChange={(event) => setNewSale(event.target.value)}
                    />
                  </label>
                  <button type="submit">Add Size Price</button>
                </form>
                <ul>
                  {sizes.map((size, index) => (
                    <li key={index}>
                      {size.size} - {size.price} - {size.sale}
                      <div
                        onClick={() => handleDeleteSizes(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIcon />
                      </div>
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
            <div className="right">
              <form onSubmit={handleAdd}>
                <div className="formInput">
                  <label htmlFor="file">
                    Choose Image:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    required
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />

                  <div className="formInput">
                    <label htmlFor="categoryId">Category</label>
                    <input
                      id="categoryId"
                      type="text"
                      value={categoryId}
                      placeholder="Category"
                      readOnly={true}
                    />
                    {/* <div className="comboboxCell">
                      <CategoryCombobox
                        setCategoryIdFun={setCategoryIdFun}
                        tableFire="categories"
                      />
                    </div> */}
                  </div>
                </div>

                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    <input
                      id={input.id}
                      type={input.type}
                      step={input.step}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      required={input.required ? true : false}
                    />
                  </div>
                ))}
                {/* <TextField
                  className="formInput"
                  id="productSize"
                  key="productSize"
                  label="Size"
                  type="text"
                  placeholder="S, M, L, XL"
                  value={productSize}
                  onChange={(e) => setProductSize(e.target.value)}
                /> */}
                <TextField
                  className="formInput"
                  id="productAdvertise"
                  key="productAdvertise"
                  label="Advertisement"
                  type="text"
                  placeholder="Buy 3 get 1 free"
                  value={productAdvertise}
                  onChange={(e) => setProductAdvertise(e.target.value)}
                />
                <textarea
                  className="formInput"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={8}
                  cols={40}
                  placeholder="Product Description"
                />
                <Box sx={{ display: "flex", gap: 4 }}>
                  <FormControlLabel
                    className="formInput"
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={onSaleChecker}
                        onChange={handleSaleChange}
                      />
                    }
                    label="On Sale"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    className="formInput"
                    value="end"
                    control={
                      <Switch
                        color="primary"
                        checked={dollarChecker}
                        onChange={handleDollarChange}
                      />
                    }
                    label="$"
                    labelPlacement="end"
                  />
                </Box>

                <div className="sendButton">
                  <button disabled={per !== null && per < 100} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="imagesProduct">
        <ProductImages inputs={inputs} title="Edit Product" />
      </div>
    </>
  );
};

export default NewProduct;
